import { Component, Input, Inject, OnChanges } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { CommonModule } from '@angular/common';
import { QRButton } from '../../core/models/qr-button.model';
import { QRButtonComponent } from '../ui/qr-button/qr-button.component';

@Component({
  selector: 'qr-card-information',
  templateUrl: './qr-card-information.component.html',
  styleUrls: ['./qr-card-information.component.scss'],
  standalone: true,
  imports: [QRButtonComponent, L10nTranslatePipe, CommonModule],
})
export class QrCardInformationComponent implements OnChanges {
  @Input() data: any;

  public buttonAction: QRButton = {
    id: 'button-action',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  backgroundImage = '/assets/images/illustration-test.png';
  noUrl = false;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  /*
          ngOnChanges: Detecta que hubo un cambio en el componente
          */
  ngOnChanges(): void {
    if (this.data.noUrl) {
      this.noUrl = this.data.noUrl;
    }
    this.backgroundImage = this.data.backgroundImage;
    this.buttonAction = {
      ...this.buttonAction,
      value: this.data.buttonText,
    };
  }
}
